<template>
    <v-container grid-list-xl fluid>
        <listarCliente v-bind:titulo="'Cargar Carrito de Compra para'"></listarCliente>
        <articuloEquivalente></articuloEquivalente>
    </v-container>
</template>

<script>
import ArticuloEquivalente from '@/components/articulo/ArticuloEquivalente.vue';
import clienteService from "@/services/ClienteServices";
import listarCliente from "@/components/cliente/ListarClientes.vue";
export default {
    name: 'verArticulosEquivalentes',
    components: {ArticuloEquivalente, listarCliente},
    data() {
        return {
            cliente: {},
            isAdmin: false
        };
    },
    created() {
        this.cliente = clienteService.current();
        this.$bus.$on('SelectorClienteSetCliente', () => {
            this.cliente = clienteService.current();
        });
    }
}
</script>