<template>
  <section>
    <v-layout row wrap>
      <v-flex x12>
        <h3>Artículos relacionados: {{ art_origen.art_descri }}</h3>
        <v-btn @click="volver()">Volver al listado</v-btn>
      </v-flex>
      <v-flex xs12>
        <alert
          v-bind:tipo="notificacion.tipo"
          v-bind:mensaje="notificacion.mensaje"
        ></alert>
        <v-spacer></v-spacer>

        <v-card v-show="!isLoading">
          <v-toolbar card color="white">
            <v-text-field
              flat
              solo
              disabled
              label="Listado de articulos"
              single-line
              hide-details
            ></v-text-field>

            <v-layout row wrap>
              <v-flex xs6 sm4 lg4>
                <v-icon color="error">assessment</v-icon> Sin Stock
              </v-flex>
              <v-flex xs6 sm4 lg4>
                <v-icon color="warning">assessment</v-icon>Stock Critico
              </v-flex>
              <v-flex xs6 sm4 lg4>
                <v-icon color="success">assessment</v-icon>Stock Disponible
              </v-flex>
            </v-layout>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout v-resize="onResize" column>
              <v-data-table
                :headers="headers"
                :items="articulos"
                :hide-headers="isMobile"
                :class="{ mobile: isMobile }"
                :rows-per-page-items="rowsperpageitems"
                :loading="isLoading"
                :loading-text="'Buscando artículos en la base de datos'"
              >
                <template slot="items" slot-scope="props">
                  <tr v-if="!isMobile">
                    <td>{{ props.item.art_articu }}</td>
                    <td>{{ props.item.art_descri }}</td>
                    <td>
                      <span v-if="props.item.ar4_websto == 0">Consultar</span>
                      <visualizarStock
                        v-bind:tipo="props.item.stockcritico"
                        v-else
                      ></visualizarStock>
                    </td>
                    <td>
                      <span v-if="props.item.ar4_webpre == 0">Consultar</span>
                      <span v-else>{{ props.item.precioFinal | moneda }}</span>
                    </td>
                    <td class="notbreak">
                      <addItem v-bind:art="props.item"></addItem>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>
                      <ul class="flex-content">
                        <li class="flex-item" data-label="Cod. Corto">
                          {{ props.item.art_articu }}
                        </li>
                        <li class="flex-item" data-label="Stock">
                          <span v-if="props.item.ar4_websto == 0"
                            >Consultar</span
                          >
                          <visualizarStock
                            v-bind:tipo="props.item.stockcritico"
                            v-else
                          ></visualizarStock>
                        </li>
                        <li class="flex-item" data-label="Precio">
                          <span v-if="props.item.ar4_webpre == 0"
                            >Consultar</span
                          >
                          <span v-else>{{
                            props.item.precioFinal | moneda
                          }}</span>
                        </li>
                        <li class="flex-item" data-label="Descri.">
                          {{ props.item.art_descri }}
                        </li>
                        <li class="flex-item" data-label="Agregar al carrito">
                          <addItem
                            v-bind:art="props.item"
                            v-bind:isMobile="isMobile"
                          ></addItem>
                        </li>
                        <li class="enter"></li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>
  
  <script>
import DataTableMixin from "@/mixins/DataTableMixin";
import articuloservice from "@/services/ArticuloServices";
import visualizarStock from "@/components/articulo/visualizarStock.vue";
import alert from "@/components/alert/alert.vue";
import addItem from "@/components/pedidos/addItem.vue";
import utils from "@/plugins/Utils.js";

export default {
  name: "ConsultarArticulosEquivalentes",
  mixins: [DataTableMixin, utils.mixins],
  components: {
    alert,
    visualizarStock,
    addItem,
  },
  data() {
    return {
      art_origen: {},
      articulos: [],
      isLoading: false,
      isAgregar: false,
      notificacion: { mensaje: "", tipo: "error" },
      isMobile: false,
      articulo: { codart: "", cantidad: "" },
      headers: [
        {
          text: "Cod. Corto",
          align: "left",
          value: "art_articu",
          sortable: true,
          width: 110,
        },
        {
          text: "Descri.",
          align: "left",
          value: "art_descri",
          sortable: true,
        },
        { text: "Stock", value: "sto_stoact", sortable: false, width: 100 },
        { text: "Precio", value: "precioFinal", sortable: false, width: 100 },
        { text: "Agregar al Carrito", value: "-", sortable: false, width: 100 },
      ],
      direccionesorden: [
        { cod: "ASC", descri: "menor a mayor" },
        { cod: "DESC", descri: "mayor a menor" },
      ],
      Itemorden: [
        { cod: "art_descri", descri: "Descripción" },
        { cod: "precioFinal", descri: "Precio" },
      ],
    };
  },
  props: {
    inicio: {
      type: Array,
      default: () => [{ descri: "ARTICULOS" }],
    },
  },
  created() {
    this.Cargar(this.$route.params.idArt);
    this.CargarEquivalentes(this.$route.params.idArt);
  },
  methods: {
    volver(){
      this.$router.go(-1)
    },
    Cargar(id) {
      articuloservice
        .consultarPorCodigo(id)
        .then((res) => {
          this.art_origen = res;
        })
        .catch((err) => {
          this.notificacion.tipo = "error";
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        });
    },
    CargarEquivalentes(id) {
      this.isLoading = true;
      articuloservice
        .consultarEquivalentes(id)
        .then((res) => {
          this.articulos = res;
        })
        .catch((err) => {
          this.notificacion.tipo = "error";
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
  